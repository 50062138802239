import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import Loader from "../../components/Loader";
import NotFound from "../NotFound";
import SubNavigation from "../../components/SubNavigation";
import LargeHeader from "../../components/LargeHeader";
import OptionFooter from "../../components/OptionFooter";
import ScreenConfigDevices from "./screenConfigDevices";
import ScreenConfigContainer from "./container";
import SelectLocation from "./selectLocation";
import * as screenActions from "../../actions/screens";
import style from "./style.module.scss";

class ScreenConfig extends Component {

  constructor(props) {
    super(props);

    this.onEditNameSave = this.onEditNameSave.bind(this);
    this.onCopyURLClicked = this.onCopyURLClicked.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.isFormValid = this.isFormValid.bind(this);

    if (isEmpty(props.match.params.configId)) {
      this.props.clearScreenData();
    }
    else if (this.props.screen.id !== props.match.params.configId) {
      this.props.getScreen(props.match.params.configId);
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
      event.preventDefault(); // Prevent the default save dialog

      if (this.isFormValid()) {
        this.onSave();
      }
    }
  }

  onEditNameSave(name) {
    this.props.updateScreen(this.props.screen.id, { name });
  }

  onCopyURLClicked() {
    let domain = window.location.host.replace("admin.", "screen.");
    const url = `https://${domain}/${this.props.match.params.companyId}/${this.props.match.params.configId}`;

    // Add url to clipboard
    window.Clipboard.copy(url);
  }

  onDeleteClicked() {
    this.props.deleteScreen(this.props.screen.id, this.props.history.push);
  }

  onSave() {
    const { push } = this.props.history;

    // Copy form to make changes for upload
    const form = JSON.parse(JSON.stringify(this.props.form));
    delete form.id;

    if (form.config.ui) {
      try {
        form.config.ui = JSON.parse(form.config.ui);
      }
      catch (e) {
        delete form.config.ui;
      }
    }

    if (form.config.view) {
      try {
        form.config.view = JSON.parse(form.config.view);
      }
      catch (e) {
        delete form.config.view;
      }
    }

    this.props.updateScreen(this.props.screen.id, form, push);
  }

  onCancel() {
    this.props.resetScreenForm();
    this.props.history.push(`/companies/${this.props.match.params.companyId}/screens`);
  }

  isFormValid() {

    // Validate overlay UI
    let overlayUIValid = false;
    let overlayUIChanged = false;
    let oldOverlayUI = get(this.props.screen, "config.ui", "");
    let newOverlayUI = get(this.props, "form.config.ui", "");
    if (oldOverlayUI === "" && newOverlayUI === "") {
      overlayUIValid = true;
    }
    if (get(this.props, "form.config.ui", false)) {
      try {
        const newUIObject = JSON.parse(this.props.form.config.ui);
        overlayUIValid = true;

        // Need to stringify again to get the correct format for comparison
        if (JSON.stringify(newUIObject, null, 2) !== JSON.stringify(oldOverlayUI, null, 2)) {
          overlayUIChanged = true;
        }
      }
      catch (e) {
        overlayUIChanged = true;
        overlayUIValid = false;
      }
    }
    else {
      overlayUIValid = true;
    }

    // Validate layout
    let layoutValid = false;
    let layoutChanged = false;
    let oldLayout = get(this.props.screen, "config.view", null);
    let newLayout = get(this.props, "form.config.view", null);
    if (oldLayout === null && newLayout === null) {
      layoutValid = true;
    }
    else if (get(this.props, "form.config.view", false)) {
      try {
        // Use less strict JSON parsing to allow for comments and trailing commas
        // const newLayoutObject = JSON5.parse(this.props.form.config.view);
        const newLayoutObject = JSON.parse(this.props.form.config.view);
        layoutValid = true;

        // Need to stringify again to get the correct format for comparison
        if (JSON.stringify(newLayoutObject, null, 2) !== JSON.stringify(oldLayout, null, 2)) {
          layoutChanged = true;
        }
      }
      catch (e) {
        layoutChanged = true;
        layoutValid = false;
      }
    }
    else {
      layoutValid = true;
    }

    // Validate hex color
    const yourLocationColorValid = /^(#[A-Fa-f0-9]{3,6})?$/i.test(get(this.props.form, "config.yourLocation.color", ""));

    const showFooter = ((
      this.props.form.name &&
      this.props.form.name.length > 0 &&
      get(this.props.form, "config.initialFloorId", false)) &&
      (this.props.form.name !== this.props.screen.name ||
        get(this.props.form, "config.initialFloorId", undefined) !== get(this.props.screen, "config.initialFloorId", undefined) ||
        get(this.props.form, "config.showTitle", false) !== get(this.props.screen, "config.showTitle", false) ||
        get(this.props.form, "config.useThreeD", false) !== get(this.props.screen, "config.useThreeD", false) ||
        get(this.props.form, "config.useCircleLabels", false) !== get(this.props.screen, "config.useCircleLabels", false) ||
        get(this.props.form, "config.hideMapBackground", false) !== get(this.props.screen, "config.hideMapBackground", false) ||
        get(this.props.form, "config.allowInteractions", false) !== get(this.props.screen, "config.allowInteractions", false) ||
        get(this.props.form, "config.hideViewIdentifier", false) !== get(this.props.screen, "config.hideViewIdentifier", false) ||
        get(this.props.form, "config.showVacant", true) !== get(this.props.screen, "config.showVacant", true) ||
        get(this.props.form, "config.showOccupied", true) !== get(this.props.screen, "config.showOccupied", true) ||
        get(this.props.form, "config.showVacantBooked", false) !== get(this.props.screen, "config.showVacantBooked", false) ||
        get(this.props.form, "config.useMeetingRoomCalendars", false) !== get(this.props.screen, "config.useMeetingRoomCalendars", false) ||
        get(this.props.form, "config.initialBearing", 0) !== get(this.props.screen, "config.initialBearing", 0) ||
        get(this.props.form, "config.initialPitch", 0) !== get(this.props.screen, "config.initialPitch", 0) ||
        get(this.props.form, "config.initialLocation.ne.lat", "") !== get(this.props.screen, "config.initialLocation.ne.lat", "") ||
        get(this.props.form, "config.initialLocation.ne.lng", "") !== get(this.props.screen, "config.initialLocation.ne.lng", "") ||
        get(this.props.form, "config.initialLocation.sw.lat", "") !== get(this.props.screen, "config.initialLocation.sw.lat", "") ||
        get(this.props.form, "config.initialLocation.sw.lng", "") !== get(this.props.screen, "config.initialLocation.sw.lng", "") ||
        get(this.props.form, "config.yourLocation.lat", "") !== get(this.props.screen, "config.yourLocation.lat", "") ||
        get(this.props.form, "config.yourLocation.lng", "") !== get(this.props.screen, "config.yourLocation.lng", "") ||
        get(this.props.form, "config.yourLocation.color", "") !== get(this.props.screen, "config.yourLocation.color", "") ||
        get(this.props.form, "config.statusFilter", "") !== get(this.props.screen, "config.statusFilter", "") ||
        get(this.props.form, "config.labelFilter", "") !== get(this.props.screen, "config.labelFilter", "") ||
        get(this.props.form, "config.iconFilter", "") !== get(this.props.screen, "config.iconFilter", "") ||
        get(this.props.form, "config.locationIds", "") !== get(this.props.screen, "config.locationIds", "") ||
        get(this.props.form, "config.language", "") !== get(this.props.screen, "config.language", "") ||
        overlayUIChanged || layoutChanged));

    const pitchValid = !isNaN(get(this.props.form, "config.initialPitch", 0)) && get(this.props.form, "config.initialPitch", 0) >= 0 && get(this.props.form, "config.initialPitch", 0) <= 60;

    return showFooter && overlayUIValid && layoutValid && yourLocationColorValid && pitchValid && !this.props.isScreenLoading;
  }

  render() {
    // console.log("ScreenContainer", this.props);
    if (this.props.isAuthLoading || isEmpty(this.props.screen)) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/configs/${this.props.match.params.configId}/edit/details`, label: `Details` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/configs/${this.props.match.params.configId}/edit/devices`, label: `Devices` });

    const inDev = window.location.origin === "https://admin.dev.bldng.ai";
    const tempAuthCheck = inDev || ["christoffer@bldng.ai", "jorgen@bldng.ai"].includes(get(this.props.auth, "user.email", ""));

    if (this.props.auth.hasSupportRole) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/configs/${this.props.match.params.configId}/edit/overlay`, label: `Overlay`, hiddenForAdmins: true });

      if (tempAuthCheck) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/configs/${this.props.match.params.configId}/edit/layout`, label: `Layout`, hiddenForAdmins: true });
      }
    }

    const saveDisabled = !this.isFormValid();

    return (
      <div className={style.page}>
        <LargeHeader
          title={this.props.screen.name}
          canUpdate={true}
          canUpdateName={true}
          canDelete={true}
          onNameSaveClicked={this.onEditNameSave}
          onDeleteClicked={this.onDeleteClicked}
          onCopyURLClicked={this.onCopyURLClicked}
        />
        <SubNavigation links={subNavigationLinks} />
        <Switch>
          <Redirect exact from="/companies/:companyId/screens/configs/:configId/edit" to={{ pathname: `/companies/${this.props.match.params.companyId}/screens/configs/${this.props.match.params.configId}/edit/details` }} />
          <Route path="/companies/:companyId/screens/configs/:configId/edit/details" children={(props) => <ScreenConfigContainer {...props} configId={this.props.match.params.configId} />} />
          <Route path="/companies/:companyId/screens/configs/:configId/edit/devices" children={(props) => <ScreenConfigDevices {...props} configId={this.props.match.params.configId} />} />
          <Route path="/companies/:companyId/screens/configs/:configId/edit/overlay" children={(props) => <ScreenConfigContainer {...props} configId={this.props.match.params.configId} />} />
          { tempAuthCheck && <Route path="/companies/:companyId/screens/configs/:configId/edit/layout" children={(props) => <ScreenConfigContainer {...props} configId={this.props.match.params.configId} />} /> }
          <Route path="/companies/:companyId/screens/configs/:configId/edit/locations/:locationId" children={(props) => (<SelectLocation {...props} configId={this.props.match.params.configId} locationId={props.match.params.locationId} />)} />
          <Route render={NotFound} />
        </Switch>

        {
          <OptionFooter inline={true} cancel={this.onCancel} options={[{ label: "Save", callback: this.onSave, disabled: saveDisabled }]} />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    screen: state.screen,
    form: state.screen.form,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    isScreenLoading: state.loading.screen
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getScreen: screenActions.getScreen,
    updateScreen: screenActions.updateScreen,
    deleteScreen: screenActions.deleteScreen,
    updateScreenForm: screenActions.updateScreenForm,
    resetScreenForm: screenActions.resetScreenForm,
    clearScreenData: screenActions.clearScreenData
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenConfig);
